import React, { useState, useEffect } from "react";
import {
  GelBoxLayout,
  GelButton,
  GelContainerLite,
  GelParagraph,
  GelForm,
  GelFormField,
  GelNumberInput,
  useGelFormData,
  GelSpinner,
  GelCaption,
} from "@tal-gel/components";
import { useNavigate } from "react-router-dom";
import { API } from "../../constants/constants";
import { useCookies } from "react-cookie";
import axios, { AxiosResponse, AxiosError } from "axios";
import { getGelTokens } from "@tal-gel/theming";
import { VALIDATE_MFA_QUERY, RESEND_MFA } from "../../graphql/graphql-bff";
import { GET_MFAPAGE_CONTENT_QUERY } from "../../graphql/graphql-contentful";
import Header from "../../common/header";
import Footer from "../../common/footer";

const VerifyPasscode = () => {
  const { global: { sizeBaseX15, themeColorDanger, themeColorWhite, themeColorGrayT20, borderSizeSm } } = getGelTokens()

  const [contentfulData, setcontenfulData] = useState<null | {
    mmcTfnPageCollection: any;
  }>(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [userName, setUserName] = useState("");
  const [memberNumber, setMemberNumber] = useState("");
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [resendError, setResendError] = useState(false);

  const [{aid, rid, ssoid, tpid, sid}] = useCookies(["aid", "rid", "ssoid", "tpid", "sid"]);

  const { formData, setFormData } = useGelFormData({
    passcode: "",
  });

  const onSubmit = () => {
    setIsFormDisabled(true);

    const variables = {
      passCodeValidationRequest: {
        userName: userName,
        memberNumber: memberNumber,
        passcode: formData.passcode,
        source: "TFN",
      },
    };

    axios
      .post(
        API.BASEURL,
        {
          query: VALIDATE_MFA_QUERY,
          variables: variables,
        },
        {
          headers: {
            "x-aid": aid,
            "x-rid": rid,
            ssoid,
            tpid,
            sid,
          },
        }
      )
      .then((response: AxiosResponse<any>) => {
        navigate("/tfn-form", {
          state: {
            auth: true,
          },
        });
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          navigate("/error");
        } else {
          setIsFormDisabled(false);
          setIsError(true);
          setErrorMessage(
            contentfulData?.mmcTfnPageCollection.items[0]?.errorMessageContent
              .invalidPasscode
          );
        }
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value === "") {
      setIsError(false);
      setErrorMessage("");
      setIsDisabled(true);
    } else if (e.target.value.length < 6) {
      setIsError(true);
      setIsDisabled(true);
      setErrorMessage(
        contentfulData?.mmcTfnPageCollection.items[0]?.errorMessageContent
          .passcodeMinLength
      );
    } else {
      setIsDisabled(false);
      setIsError(false);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    setIsLoading(true);

    //contentful call
    axios
      .post(API.CONTENTFUL_URL, {
        query: GET_MFAPAGE_CONTENT_QUERY,
      })
      .then(async (response) => {
        setcontenfulData(response.data?.data);
      });

    const queryParams = new URLSearchParams(window.location.href);
    const origin = queryParams.get("origin");

    const pattern = /info=([^&]+)/;
    const match = window.location.href.match(pattern);
    const info = match ? match[1] : "";
    const params = info.split("_");

    setUserName(params[0]);
    const claimId = params[1];
    const requirementId = params[2];
    setMemberNumber(params[3]);

    if (claimId && requirementId && origin) {
      sessionStorage.setItem("claimId", claimId);
      sessionStorage.setItem("requirementId", requirementId);
      sessionStorage.setItem("origin", origin);
    }

    setIsLoading(false);
  }, []);

  const onClickResend = () => {
    const requestData = {
      userName: userName,
      memberNumber: memberNumber,
      source: "TFN",
    };

    axios
      .post(
        API.BASEURL,
        {
          query: RESEND_MFA,
          variables: {
            mfaConfigRequest: requestData,
          },
        },
        {
          headers: {
            "x-aid": aid,
            "x-rid": rid,
            ssoid,
            tpid,
            sid,
          },
        }
      )
      .then(async (response) => {
        setResendError(false);
      })
      .catch((error: AxiosError) => {
        console.log(error);
        setResendError(true);
      });
  };

  return (
    <div>
      <Header
        title={contentfulData?.mmcTfnPageCollection.items[0]?.mfaPageTitle}
      />
      <GelContainerLite>
        {isLoading && <GelSpinner large overlay />}
        <GelBoxLayout
          gap="medium"
          alignment="start"
          space="auto"
          distribution="spaceBetween"
          vertical="true"
          padding="large"
          margin="large"
          style={{
            marginTop: `-${sizeBaseX15}px`,
            backgroundColor: themeColorWhite,
            border: `${borderSizeSm}px solid`,
            borderColor: themeColorGrayT20,
          }}
        >
          <GelParagraph>
            {contentfulData?.mmcTfnPageCollection.items[0]?.mfaDescription}
          </GelParagraph>
          <GelForm labelAtTop disabled={isFormDisabled} onSubmit={onSubmit}>
            <GelFormField label="Enter Verification Code">
              <GelNumberInput
                name="passcode"
                defaultValue={formData.passcode}
                onChange={onChange}
                error={isError}
                noFormat
                integer
                maxLength="6"
                required
                requiredErrorMsg="This field is required"
              />
              {isError && (
                <GelBoxLayout marginTop="small">
                  <GelCaption
                    condensed
                    style={{
                      color: themeColorDanger,
                    }}
                  >
                    {errorMessage}
                  </GelCaption>
                </GelBoxLayout>
              )}
              {resendError && (
                <GelBoxLayout marginTop="small">
                  <GelCaption
                    condensed
                    style={{
                      color: themeColorDanger,
                    }}
                  >
                    Resend failed
                  </GelCaption>
                </GelBoxLayout>
              )}
            </GelFormField>

            <GelFormField>
              <GelButton
                name="" // Makes it a managed component
                tertiary
                small
                onClick={onClickResend}
              >
                {
                  contentfulData?.mmcTfnPageCollection.items[0]
                    ?.mfaResendButtonText
                }
              </GelButton>
            </GelFormField>

            <GelBoxLayout alignment="start" gap="small">
              <GelFormField>
                <GelButton
                  name="" // Makes it a managed component
                  primary
                  medium
                  submit
                  onSubmit={onSubmit}
                  disabled={isDisabled}
                >
                  {
                    contentfulData?.mmcTfnPageCollection.items[0]
                      ?.mfaSubmitButtonText
                  }
                </GelButton>
              </GelFormField>
            </GelBoxLayout>
          </GelForm>
        </GelBoxLayout>
      </GelContainerLite>
      <Footer />
    </div>
  );
};

export default VerifyPasscode;
