import {
  GelButton,
  GelBoxLayout,
  GelModal,
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { ReactNode } from "react";

interface Props {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  content: ReactNode;
}
const TooltipModal: React.FC<Props> = ({
  openModal,
  setOpenModal,
  content,
}) => {
  return (
    <GelModal
      width="sm"
      open={openModal}
      onClose={() => setOpenModal(false)}
      sticky
      action={
        <GelBoxLayout gap="small" space="auto">
          <GelButton
            secondary
            medium
            width={getGelTokens().global.sizeBaseUnit * 26}
            onClick={() => setOpenModal(false)}
          >
            Close
          </GelButton>
        </GelBoxLayout>
      }
    >
      {content}
    </GelModal>
  );
};

export default TooltipModal;
